.alert {
  display: flex;
  vertical-align: middle;
  @include media-breakpoint-down(xs) {
    padding: 0.75rem;
  }
  &__icon {
    font-size: 14px;
    min-width: 14px;
    display: block;
    margin-top: 5px;
    margin-right: 0.5rem;
    @include media-breakpoint-up(sm) {
      margin-right: 1rem;
    }
  }

  &-primary {
    color: $c-blue-main;
    background-color: $c-blue-10;
    border-color: $c-blue-secondary;
  }

  &-danger {
    color: $c-blue-main;
    background-color: $c-blue-10;
    border-color: $c-blue-secondary;
  }

  &-info {
    color: $c-blue-main;
    background-color: $c-blue-10;
    border-color: $c-blue-secondary;
  }
}
