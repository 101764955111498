.subsection {
  &-menu {
    grid-area: submenu;
    background: $subsection-menu-bg;
    width: 350px;
    height: 100vh;
    border-right: $subsection-menu-bg;
    font-weight: 700;
    padding-top: 0;
    overflow-y: auto;

    &__header {
      padding: 3rem 2rem 1rem;
    }
    &__title {
      font-size: 1.25rem;
      line-height: 1.2;
      margin-bottom: 40px;
    }
    &__id {
      margin-bottom: 8px;
    }

    &__badge {
      width: 60px;
      height: 20px;
      border-radius: 1rem;
      font-size: 12px;
      font-weight: bolder;
      color: white;
      padding: 0.05rem 0.75rem;
      text-align: center;
      &--bound {
        background-color: $c-mine-shaft;
      }
      &--cancelled {
        background-color: $policy-status-canceled;
      }
      &--expired {
        background-color: $policy-status-expired;
      }
    }

    &__date {
      font-size: 12px;
      font-weight: 400;
    }

    &__item {
      padding: 1.25rem 2rem;
      border-top: 1px solid $subsection-menu-bg;
      border-bottom: 1px solid $subsection-menu-bg;
      margin: 0;
      display: block;
      color: $c-warm-black;

      &:hover,
      &.active {
        background: white;
        border-top: 1px solid $c-primary;
        border-bottom: 1px solid $c-primary;
        text-decoration: none;
        color: $c-gold;
      }
    }

    &--ellipsis {
      color: $c-primary;
    }
  }

  &-table {
    border: 1px solid $subsection-menu-bg;
    line-height: 1.71;
    & thead th {
      border-top-width: 0px;
      border-bottom-width: 0px;
      font-weight: bolder;
      font-family: $font-family-raleway;
      background-color: $c-blue-10;
    }

    tr:hover {
      background: $c-gold-5;
    }

    &__caution-icon {
      color: $c-gold-alert;
    }

    &__badge {
      width: 76px;
      height: 20px;
      border-radius: 1px;
      font-size: 12px;
      font-weight: bolder;
      color: white;
      padding: 0rem 0.75em 0.05rem 0.75rem;
      margin: 0.25rem 0 0 0;
      text-align: center;
      display: block;
      &--blocked {
        background-color: #c60c30;
      }
    }
  }

  &__wrapper {
    display: grid;
    position: relative;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "submenu subcontainer";
    overflow-x: auto;
    overflow-y: hidden;
    height: 100vh;
  }

  &__container {
    min-width: 100%;
    width: fit-content;
    height: 100vh;
    display: grid;
    grid-area: subcontainer;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
