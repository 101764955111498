// GBLI Colours
$c-gold: #9e8146 !default;
$c-gold-alert: #d29b0d;
$c-gold-5: #f4f2f0;
$c-gold-light: #f5f2ec !default;
$c-gold-light-secondary: #dbc091 !default;
$c-gold-ultralight: #fcf9f4;
$c-blue-main: #0d3a68;
$c-blue-secondary: #466cb7 !default;
$c-blue-10: #ecf0f7;
$c-gray-light: #e0e3e2 !default;
$c-dark-gray: #444647 !default;
$c-gray-200: #eeeeee !default;
$c-gray-300: #e0e0e0 !default;
$c-gray-400: #bdbdbd !default;
$c-gray-500: #9e9e9e !default;
$c-gray-600: #757575 !default;
$c-warm-black: #231f20 !default;
$c-off-white: #fafafa;

// Generic Colours
$c-red: #cf3a2f !default;
$c-yellow: #ffb81c !default;
$c-green: #5e9c3f !default;
$c-tundora: #484848 !default;
$c-gum-leaf: #a9d3bd !default;
$c-white: #ffffff !default;
$c-shilo: #e8aab5 !default;
$c-mine-shaft: #333333 !default;

$c-primary: $c-gold;
$c-secondary: $c-gold;
$c-secondary-dark: $c-gold;
$c-success: $c-blue-secondary;
$c-danger: $c-red;
$c-warning: $c-yellow;
$c-info: $c-gray-400;
$c-light: $c-gray-light;
$c-dark: $c-dark-gray;

// Alert colors
$c-alert-primary: lighten($c-blue-secondary, 10%);
$c-alert-secondary: lighten($c-gray-600, 10%);
$c-alert-success: lighten($c-green, 10%);
$c-alert-danger: lighten($c-red, 10%);
$c-alert-info: lighten($c-gray-400, 10%);
$c-alert-light: lighten($c-gray-light, 10%);
$c-alert-dark: lighten($c-dark-gray, 10%);

// Fonts
$font-family-raleway: "Raleway", sans-serif;
$font-family-hind: "Hind", sans-serif;
$font-family-merriweather: "Merriweather", sans-serif;

// Other
$main-menu-bg: $c-gray-200;
$main-menu-title-bg: $c-blue-main;
$menu-item-font-color: rgba(255, 255, 255, 0.5);
$caution-icon: #d6612b;
$subsection-menu-bg: #f0f0f0;
$policy-status-success: #5e9c3f;
$policy-status-canceled: #cf3a2f;
$policy-status-expired: $c-blue-secondary;
$link: $c-warm-black;
$policies-border: 1px solid rgba(0, 0, 0, 0.1);
$policy-ref: #898d8e;
$policy-text-area-counter: #898d8e;
$policy-update-alert-bg: #fff4dc;
$policy-update-alert: #d6612b;
$policy-update-alert-border: #f2cfbf;
$referral-toast-bg: #00a86d;
$additional-insured-add-button: #fdaf13;
$additional-insured-hover-button-background: #ffeabf;
$additional-insured-disabled-button: #9e9e9e;

// Spacing
$spacing-xxxs: 0.25rem; // 4px
$spacing-xxs: 0.5rem; // 8px
$spacing-xs: 1rem; // 16px
$spacing-s: 1.5rem; // 24px
$spacing-m: 2rem; // 32px
$spacing-l: 2.5rem; // 40px

// Mixin to target touch device hover state. Disable hover styles on touch for specific elements
@mixin touch-hover() {
  .touch &:hover {
    @content;
  }
}
