.users-form-container {
  .form-control {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.25rem;
  }

  .form--mb-100 {
    margin-bottom: 100px;
  }

  .btn {
    padding: 0.625rem 0.75rem;
    margin-bottom: 0.25rem;

    &__role {
      width: 100%;

      @include media-breakpoint-down(lg) {
        max-width: 170px;
        @include media-breakpoint-up(lg) {
          letter-spacing: initial;
          padding: 0.625rem 0.125rem;
        }
      }
    }

    &__add {
      width: 170px;
    }
  }

  .form-group {
    &--h-75 {
      height: 75px;
    }
  }

  .type-container {
    .card {
      border-color: $c-gold;
      border-radius: 4px 0 4px 4px;
    }

    .radio-container {
      width: 160px;
      flex-shrink: 0;
    }

    .radio-large {
      .custom-control-label {
        &::after {
          font-size: 1.25rem;
          left: -20px;
          top: -5px;
          content: "✓";
          color: #fff;
        }
      }

      .custom-control-input:checked ~ .custom-control-label {
        &::before {
          background-color: $c-gold;
        }

        &::after {
          background-color: inherit;
        }
      }
    }
  }

  .new-user-success p {
    font-size: 20px;
  }

  .table {
    th,
    thead th {
      border-top: 0;
    }

    td .fa-trash-alt {
      cursor: pointer;
      color: $c-gray-400;
    }
  }
}
