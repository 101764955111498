html,
body,
#root,
.container-main {
  height: 100%;
  max-width: 100%;
  font-family: $font-family-raleway;
}

.container-main {
  position: relative;
  display: grid;
  overflow: hidden;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "sidebar main";
}

.page-add-producer,
.page-add-organization,
.page-blocked-venues,
.page-add-association {
  & .admin-body {
    overflow: auto;
  }
}

.admin-body {
  width: 100%;
  position: relative;

  &--fullWidth {
    max-width: none;
  }
}

.scroll-part {
  overflow: auto;
  height: calc(100vh - 9px);
  min-width: 100%;
  width: calc(100vw - 350px);
}

.payment {
  &__item {
    border-bottom: $policies-border;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.input-group {
  & .form-control {
    height: 50px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-raleway;
}
