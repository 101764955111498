.loader {
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;

  &__dot {
    position: absolute;
    background-color: $c-gold;
    width: 14px;
    height: 14px;
    border-radius: 9px;
    animation-name: bounce_rotate;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-direction: normal;

    &--1 {
      left: 0;
      top: 24px;
      animation-delay: 0.41s;
    }

    &--2 {
      left: 6px;
      top: 6px;
      animation-delay: 0.55s;
    }

    &--3 {
      top: 0;
      left: 24px;
      animation-delay: 0.69s;
    }
    &--4 {
      right: 6px;
      top: 6px;
      animation-delay: 0.83s;
    }
    &--5 {
      right: 0;
      top: 24px;
      animation-delay: 0.97s;
    }
    &--6 {
      right: 6px;
      bottom: 6px;
      animation-delay: 1.1s;
    }
    &--7 {
      left: 24px;
      bottom: 0;
      animation-delay: 1.24s;
    }
    &--8 {
      left: 6px;
      bottom: 6px;
      animation-delay: 1.38s;
    }
  }
}

@keyframes bounce_rotate {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

@-o-keyframes bounce_rotate {
  0% {
    -o-transform: scale(1);
  }

  100% {
    -o-transform: scale(0.5);
  }
}

@-ms-keyframes bounce_rotate {
  0% {
    -ms-transform: scale(1);
  }

  100% {
    -ms-transform: scale(0.5);
  }
}

@-webkit-keyframes bounce_rotate {
  0% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.5);
  }
}

@-moz-keyframes bounce_rotate {
  0% {
    -moz-transform: scale(1);
  }

  100% {
    -moz-transform: scale(0.5);
  }
}
