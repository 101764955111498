.pl {
  &--30 {
    padding-left: 30px;
  }
}

.pt {
  &--43 {
    padding-top: 43px;
  }
}

.w {
  &--150 {
    width: 150px;
  }
  &--100 {
    width: 100px;
  }
  &--50 {
    width: 50px;
  }
  &--250 {
    width: 250px;
  }
  &--280 {
    width: 280px;
  }
}
.mw {
  &--91 {
    max-width: 91px;
  }
  &--193 {
    max-width: 193px;
  }
  &--400 {
    max-width: 400px;
  }
  &--635 {
    max-width: 635px;
  }
  &--700 {
    max-width: 700px;
  }
  &--556 {
    max-width: 556px;
  }
  &--435 {
    max-width: 435px;
  }
  &--455 {
    max-width: 455px;
  }
  &--400 {
    max-width: 400px;
  }
  &--300 {
    max-width: 300px;
  }
  &--290 {
    max-width: 290px;
  }
  &--250 {
    max-width: 250px;
  }
  &--130 {
    max-width: 130px;
  }

  &-md {
    @include media-breakpoint-up(md) {
      &--400 {
        max-width: 400px;
      }
    }
  }
}

.mh {
  &--hf {
    min-height: calc(100vh - 210px);
  }
  &--24 {
    min-height: 24px;
  }
}

.mt {
  &--14 {
    margin-top: 14px;
  }
  &--20 {
    margin-top: 20px;
  }
  &--40 {
    margin-top: 40px;
  }
}

.mt-lg {
  @include media-breakpoint-up(lg) {
    &--14 {
      margin-top: 14px !important;
    }
    &--20 {
      margin-top: 20px;
    }
    &--40 {
      margin-top: 40px !important;
    }
  }
}

.mb {
  &--14 {
    margin-bottom: 14px;
  }
  &--20 {
    margin-bottom: 20px;
  }
  &--40 {
    margin-bottom: 40px;
  }
}

.ml {
  &--20 {
    margin-left: 20px;
  }
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.row-highlight {
  background: $c-gold-5;
}

.color-blue-secondary {
  color: $c-blue-secondary !important;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

.border-color-blue-secondary {
  border-color: $c-blue-secondary;
}

.cursor-pointer {
  cursor: pointer;
}

.undecorate-btn {
  border: none;
  background: none;
}

.h {
  &-100vh {
    height: 100vh;
  }
}

.bg-white {
  background: $c-white;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
